import { debounce } from 'lodash'
import http from '@/services/http'
import querystring from 'querystring'
import moment from 'moment'

const AnalyticsStore = {
  namespaced: true,

  state: {
    countries: [],
    geoMapSeries: [],
    offers: [],
  },

  mutations: {
    setOffers(state, offers) {
      state.offers = Array.from(offers)
        .map(offer => {
          return {
            ...offer,
            publish_date: moment(offer.publish_date || null),
          }
        })
    },

    /**
     * Get offers with just the necessary fields
     */
    getOffers(state, filters = {}) {
      // rails does this, so let's do it too
      filters.utf8 = '✓'
      let qs = querystring.stringify(filters)

      http.get(`/analytics/offers?${qs}`)
        .then((resp) => {
          const data = resp.data

          data.forEach((el) => {
            el.publish_date = moment(el.publish_date)
            el.price = Number(el.price)
            el.total_qty = Number(el.total_qty)
            el.unlock_price = Number(el.unlock_price)
          })

          state.offers = data
        })
        .catch((err) => {
          console.error('analytics/getOffers:', err)
          throw err
        })
    },

    /**
     * Get offers' series data by state
     */
    getOffersByState(state, countryId) {
      http.get(`/analytics/${countryId}/offers-by-state`)
        .then((resp) => {
          state.geoMapSeries = resp.data
        })
        .catch((err) => {
          console.error('analytics/getOffersByState:', err)
          throw err
        })
    },

    /**
     * Get list of countries with states
     */
    getCountriesWithStates(state) {
      http.get('/analytics/countries-with-states')
        .then(resp => state.countries = resp.data)
        .catch((err) => {
          console.error('analytics/getCountriesWithStates:', err)
          throw err
        })
    },
  },

  actions: {
    getOffersByState({ commit }, countryId = 1) { commit('getOffersByState', countryId) },

    fetchOffers({ commit }, filters = {}) {
      let qs = querystring.stringify(filters)

      http.get(`/analytics/offers?${qs}`)
        .then(resp => { commit('setOffers', resp.data) })
        .catch(err => { console.error('analytics/fetchOffers:', err) })
    },

    getCountriesWithStates({ commit }) { commit('getCountriesWithStates') },

    filterOffers: debounce(({ commit }, query) => {
      console.debug('filterOffers: query', query)
      query = window.encodeURIComponent(query)

      http.get('/analytics/offers', { params: { query } })
        .then(resp => {
          commit('setOffers', resp.data)
        })
        .catch(err => {
          console.error('Error fetching offers:', err)
        })
    }, 600),
  },

  getters: {
    lonlatOptions: state => {
      return state.offers
        .filter(offer => !!offer.address?.lonlat)
        .map(el => {
          return {
            key: el.id,
            text: `${el.human_id} ${el.name}`,
            value: el.address.lonlat,
          }
        })
    },
  },
}

export { AnalyticsStore }
