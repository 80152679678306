import axios from 'axios'

const el = document.querySelector('meta[name=csrf-token]')

const http = axios.create({
  baseURL: '',
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': (el ? el.content : null)
  }
})

const routes = {
  adminOffer: (id) => `/analytics/offers/${id}`,
}

export default http

export {
  http,
  routes,
}
