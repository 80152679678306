/**
 * Convert VueX' state object to FormData instance
 *
 * @see FormData https://developer.mozilla.org/en-US/docs/Web/API/FormData
 *
 * @param {object} json
 * @return {FormData} FormData representation for JSON data
 */
const json2FormData = (json) => {
  const BLANK = ' '
  const data = new FormData
  const toRemove = []

  // address
  data.set('offer[address_attributes][id]', json.address.id)
  data.set('offer[address_attributes][street1]', json.address.street1)
  data.set('offer[address_attributes][zip_code]', json.address.zip_code)
  data.set('offer[address_attributes][city]', json.address.city)
  data.set('offer[address_attributes][state_id]', json.address.state_id)
  data.set('offer[address_attributes][country_id]', json.address.country_id)
  data.set('offer[address_attributes][lat]', json.address.lat)
  data.set('offer[address_attributes][lon]', json.address.lon)

  // contact
  // force blank values so FormData sets the keys in the payload
  data.set('offer[contact_attributes][id]', json.contact.id)
  data.set('offer[contact_attributes][company]', json.contact.company)
  data.set('offer[contact_attributes][email]', json.contact.email || BLANK)
  data.set('offer[contact_attributes][name]', json.contact.name || BLANK)
  data.set('offer[contact_attributes][phone]', json.contact.phone || BLANK)
  data.set('offer[contact_attributes][description]', json.contact.description || BLANK)

  // materials
  // you cannot nest an array of objects into FormData, but this seems to work
  // at least in firefox-de & chromium
  for (let i in json.offer_materials) {
    if (json.offer_materials[i].id) {
      data.append(
        'offer[offer_materials_attributes][][id]',
        json.offer_materials[i].id
      )
    }
    data.append(
      'offer[offer_materials_attributes][][qty]',
      json.offer_materials[i].qty
    )
    if (json.offer_materials[i].conditions) {
      data.append(
        'offer[offer_materials_attributes][][conditions]',
        json.offer_materials[i].conditions
      )
    }
    data.append(
      'offer[offer_materials_attributes][][material_id]',
      json.offer_materials[i].material_id
    )
    data.append(
      'offer[offer_materials_attributes][][feature_list]',
      json.offer_materials[i].feature_list
    )
    data.append(
      'offer[offer_materials_attributes][][_destroy]',
      json.offer_materials[i]._destroy === 1 ? 1 : 0
    )
  }

  // misc
  data.append('offer[billable]', json.billable)
  data.append('offer[description]', json.description)
  data.append('offer[name]', json.name)
  data.append('offer[offer_type]', json.offer_type)
  data.append('offer[publish_date]', json.publish_date)
  data.append('offer[price]', json.price)
  data.append('offer[qty]', json.qty)
  if (json.offer_status) {
    data.append('offer[offer_status]', json.offer_status)
  }

  // see https://github.com/ambethia/recaptcha#verify_recaptcha
  data.append('g-recaptcha-response', json.recaptcha_response)

  // remove null-value keys
  for (const k of data.keys()) {
    if (!data.get(k) || data.get(k) === 'undefined' || data.get(k) === 'null') {
      toRemove.push(k)
    }
  }
  for (const i in toRemove) {
    data.delete(toRemove[i])
  }

  // images
  for (let i in json.images) {
    data.append('offer[images][]', json.images[i])
  }

  return data
}

const genAddressCustomLabel = (a) => {
  let s = ''

  if (a.street)
    s += a.street
  if (a.houseNumber)
    s += ` ${a.houseNumber}`
  if (s.length > 0)
    s += ','

  if (a.district)
    s += ` ${a.district},`
  if (a.city)
    s += ` ${a.city}`
  if (a.postalCode)
    s += ` ${a.postalCode}`
  s += ','

  if (a.state)
    s += ` ${a.state},`
  if (a.country)
    s += ` ${a.country}`

  return s
}

const UploadFormService = {
  json2FormData: json2FormData,
  genAddressCustomLabel: genAddressCustomLabel,
}

export default UploadFormService

export {
  json2FormData,
  genAddressCustomLabel,
}
