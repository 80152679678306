export const OFFER_TYPES = [
  {
    id: "sell",
    name: "Venta",
  },
  {
    id: "buy",
    name: "Compra",
  },
]

export default OFFER_TYPES
