<template>
  <sui-form @submit.prevent.stop="" class="additional-information">
    <sui-form-field>
      <label for="offer_type">Tipo de Oferta *</label>
      <sui-dropdown
        id="offer_type"
        selection
        v-if="offerTypesOptions.length > 0"
        :options="offerTypesOptions"
        v-model="offerType"
      />
    </sui-form-field>

    <sui-form-field>
      <sui-checkbox v-model="invoice" label="El material es facturable" />
    </sui-form-field>

    <sui-form-field v-if="isAdmin">
      <label for="offer_status">Status</label>
      <sui-dropdown
        id="offer_status"
        selection
        v-if="offerStatusesOptions.length > 0"
        :options="offerStatusesOptions"
        v-model="offerStatus"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="name">Nombre *</label>
      <sui-input
        :placeholder="namePlaceholder"
        id="name"
        name="name"
        required
        v-model="name"
      />
      <div v-if="!suggestionSelected">
        <i>Sugerencia</i>:
        <span
          class="link-text"
          @click="selectSuggestion"
        >{{ namePlaceholder }}</span>
      </div>
    </sui-form-field>

    <sui-form-field v-if="isAdmin">
      <sui-checkbox v-model="is_verified" label="¿La oferta está verificada?" />
    </sui-form-field>

    <sui-form-field v-if="isAdmin">
      <label for="publish_date">Fecha de Publicación</label>
      <sui-input
        placeholder="2020/12/21"
        type="date"
        id="publish_date"
        name="publish_date"
        v-model="publishDate"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="price">Precio por Kg. *</label>
      <sui-input
        placeholder="Precio deseado por el cliente"
        type="number"
        id="price"
        name="price"
        min="1"
        step="any"
        required
        v-model="price"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="comments">Comentarios adicionales</label>
      <textarea
        placeholder="Describe el estado actual del material"
        rows="4"
        id="comments"
        name="comments"
        v-model="comments"
      ></textarea>
    </sui-form-field>
  </sui-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { weight } from '@/filters'

export default {
  data () {
    return {
      suggestionSelected: false
    }
  },
  methods: {
    selectSuggestion () {
      this.$store.commit('uploadForm/updateName', this.namePlaceholder)
      this.suggestionSelected = true
    }
  },
  computed: {
    ...mapState('account', [ 'isAdmin' ]),
    ...mapGetters('offers', [ 'offerStatusesOptions', 'offerTypesOptions' ]),
    ...mapGetters('uploadForm', [ 'totalQty', ]),
    comments: {
      get() { return this.$store.state.uploadForm.form.comments },
      set(v) { this.$store.commit('uploadForm/updateComments', v) }
    },
    conditions: {
      get() { return this.$store.state.uploadForm.form.conditions },
      set(v) { this.$store.commit('uploadForm/updateConditions', v) }
    },
    invoice: {
      get() { return this.$store.state.uploadForm.form.invoice },
      set(v) { this.$store.commit('uploadForm/updateInvoice', v) }
    },
    name: {
      get() { return this.$store.state.uploadForm.form.name },
      set(v) { this.$store.commit('uploadForm/updateName', v) }
    },
    offerStatus: {
      get() { return this.$store.state.uploadForm.form.offer_status },
      set(v) { this.$store.commit('uploadForm/updateOfferStatus', v) }
    },
    offerType: {
      get() { return this.$store.state.uploadForm.form.offer_type },
      set(v) { this.$store.commit('uploadForm/updateOfferType', v) }
    },
    publishDate: {
      get() { return this.$store.state.uploadForm.form.publish_date },
      set(v) { this.$store.commit('uploadForm/updatePublishDate', v) }
    },
    is_verified: {
      get() { return this.$store.state.uploadForm.form.is_verified },
      set(v) { this.$store.commit('uploadForm/updateVerification', v) }
    },
    price: {
      get() { return this.$store.state.uploadForm.form.price },
      set(v) { this.$store.commit('uploadForm/updatePrice', v) }
    },

    namePlaceholder() {
      let qty = isNaN(this.totalQty) ? 1 : this.totalQty
      let material = 'material'

      if (this.$store.state.uploadForm.form.materials.length > 0) {
        material = this.$store.state.uploadForm.form.materials
          .map(el => `${el.main} ${el.secondary}`)
          .join(', ')
      }

      return `${weight(qty)} de ${material}`
    }
  },
  mounted() {
    this.$store.commit('uploadForm/updatePrice', 1)
  },
}
</script>

<style lang="scss" scoped>
.additional-information {
  @media (min-width: 575px) {
    div {
      &#price {
        width: 100px !important;
      }
      &#publish_date, &#offer_type {
        width: 200px !important;
      }
      &#name, &#offer_status {
        width: 300px !important;
      }
    }
  }
}
</style>
