<style lang="scss">
.offer-images {
  .offer-images-previews {
    display: flex;
    align-items: baseline;
    flex-flow: row wrap;
    justify-content: space-around;

    .offer-image-preview {
      max-width: 350px;
      flex-basis: 35%;
    }

    figure {
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      padding: 0.25rem;
      position: relative;

      .remove-offer-image-button {
        position: absolute;
        right: -1.5rem;
        top: -0.8rem;
      }
    }
  }
}
</style>

<template>
  <section class="offer-images">
    <article class="offer-images-drop-area">
      <label>
        Selecciona una o más fotos
        <input
          ref="images"
          type="file"
          id="offer_images"
          name="offer_images"
          multiple
          accept="image/*"
          @change="changePreviews"
        />
      </label>
      <button type="button" @click="clearImages">
        Borrar
      </button>
    </article>
    <hr>
    <article class="offer-images-previews">
      <figure
        v-for="(url, i) in previewImages"
        :key="i"
      >
        <img
          :src="url"
          class="offer-image-preview"
        >
      </figure>
      <figure
        v-for="(img, i) in existingImages"
        :key="i"
      >
        <img
          :src="img.url"
          class="offer-image-preview"
        >
        <sui-button
          class="remove-offer-image-button"
          color="red"
          icon="close"
          @click="remove(img)"
        />
      </figure>
    </article>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      previewImages: []
    }
  },
  methods: {
    changePreviews() {
      let files = this.$refs.images.files,
        images = [],
        i = 0
      this.previewImages = []

      for (i = 0; i < files.length; ++i) {
        const reader = new FileReader()

        reader.addEventListener('load', (ev) => {
          this.previewImages.push(ev.target.result)
        })

        reader.readAsDataURL(files.item(i))
        images.push(files.item(i))
      }

      this.$store.dispatch('uploadForm/updateImages', images)
    },
    clearImages() {
      this.previewImages = []
      this.$store.dispatch('uploadForm/updateImages', null)
    },
    remove(img) {
      this.$store.commit('uploadForm/deleteImage', img.id)
    },
  },
  computed: {
    ...mapState('uploadForm', {
      existingImages: state => state.form.existingImages
    }),
  },
}
</script>

