import Vue from 'vue'
import Vuex from 'vuex'
import {
  Account,
  Addresses,
  Analytics,
  Companies,
  Materials,
  Offers,
  Subscriptions,
  UploadForm
} from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: Account,
    addresses: {
      namespaced: true,
      ...Addresses
    },
    analytics: Analytics,
    companies: {
      namespaced: true,
      ...Companies
    },
    materials: {
      namespaced: true,
      ...Materials
    },
    offers: {
      namespaced: true,
      ...Offers
    },
    subscriptions: Subscriptions,
    uploadForm: {
      namespaced: true,
      ...UploadForm
    }
  },

  state: {
    weightUnits: [
      { key: 'kg', text: 'Kg', value: 1 },
      { key: 'ton', text: 'Tons', value: 1000 },
    ],
    here: null,
    recaptchaKey: null,
  },

  mutations: {
    setHere(state, here) { state.here = here },
    setRecaptchaKey(state, siteKey) { state.recaptchaKey = siteKey },
  },

  actions: {
    getCatalogues({ dispatch }) {
      dispatch('addresses/getCountries')
      dispatch('addresses/updateStates')
      dispatch('materials/getMaterials')
    },
    setHere({ commit }, here) { commit('setHere', here) },
    setRecaptchaKey({ commit }, siteKey) { commit('setRecaptchaKey', siteKey) },
  },

  getters: {}
})
