<template>
  <aside id="unlock-offer">
    <UnlockOffer
      :balance="balance"
      :price="price"
      :token="token"
      :url="url"
    />
  </aside>
</template>

<script>
import UnlockOffer from '@/components/UnlockOffer.vue'

export default {
  name: 'unlockOffer',
  components: {
    UnlockOffer
  },
  computed: {
    balance() {
      return this.$root.$data.balance
    },
    price() {
      return this.$root.$data.price
    },
    token() {
      return this.$root.$data.token
    },
    url() {
      return this.$root.$data.url
    }
  }
}
</script>
