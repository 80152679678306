export const OFFER_STATUSES = [
  {
    id: "available",
    name: "Disponible",
  },
  {
    id: "sold",
    name: "Vendido",
  },
  {
    id: "verifying_materials",
    name: "Verificando Materiales",
  },
  {
    id: "pending",
    name: "Pendiente",
  },
  {
    id: "verifying_availability",
    name: "Verificando Disponibilidad",
  },
  {
    id: "in_trade",
    name: "En Negociación",
  },
  {
    id: "unavailable",
    name: "No Disponible",
  },
  {
    id: "supraciclaje",
    name: "Supraciclaje",
  },
  {
    id: "moderating",
    name: "Por Moderar",
  },
  {
    id: "contact_unreachable",
    name: "Contacto Inalcanzable",
  },
]

export default OFFER_STATUSES
