<template>
  <div class="discard-form">
    <a href="#" class="danger-text pointer" @click="toggle($event)">
      <i class="trash icon"></i>
      Descartar
    </a>

    <sui-modal size="small" v-model="open">
      <header class="header">Descartar oferta</header>

      <article class="ui form content scrolling">
        <div class="field">
          <label for="discarded_reason">Razón</label>
          <input
            id="discarded_reason"
            name="discarded_reason"
            v-model="discardedReason"
          />
        </div>
      </article>

      <sui-modal-actions>
        <sui-button
          type="button"
          @click.native="toggle"
        >
          Cerrar
        </sui-button>

        <sui-button
          type="button"
          color="red"
          @click.native="discard"
        >
          Descartar
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import http from '@/services/http'

export default {
  props: {
    url: { type: String, required: true },
  },

  data() {
    return {
      discardedReason: null,
      open: false,
    }
  },

  methods: {
    discard() {
      const forgedUrl = new URL(this.url)

      if (this.discardedReason)
        forgedUrl.searchParams.set('discarded_reason', this.discardedReason)

      http.delete(forgedUrl)
        .then(resp => {
          console.debug('discard: resp =', resp)
          if (window.Turbolinks)
            window.Turbolinks.visit('/offers')
          else
            window.location.href('/offers')
        })
        .catch(err => {
          console.error('discard: err', err)
        })
    },

    toggle(ev) {
      ev.preventDefault()

      this.open = !this.open
    },
  },
}
</script>

<style lang="scss">
.discard-form {
  display: inline;
}
</style>
