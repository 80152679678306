const creditBundles = [
  2000,
  5000,
  10000,
]

const d = new Date()
const currentMonth = d.getMonth()
const currentYear = d.getFullYear()
const maxYear = currentYear + 8

const bundleOptions = function() {
  const options = []
  for (let v of creditBundles) {
    options.push({ text: `\$${v}`, value: v })
  }
  return options
}

const monthOptions = function() {
  const options = []
  for (let i = 1; i <= 12; ++i) {
    options.push({ text: (i < 10 ? `0${i}` : `${i}`), value: i })
  }
  return options
}

const yearOptions = function() {
  const options = []
  for (let i = currentYear; i <= maxYear; ++i) {
    options.push({ text: `${i}`, value: i })
  }
  return options
}


const PaymentsService = {
  currentMonth,
  currentYear,
  maxYear,
  bundleOptions,
  monthOptions,
  yearOptions,
}

export {
  currentMonth,
  currentYear,
  maxYear,
  creditBundles,
  bundleOptions,
  monthOptions,
  yearOptions,
}
