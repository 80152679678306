<template>
  <aside class="form-actions text-right">
    <vue-recaptcha
      v-if="recaptchaKey && !isAdmin"
      :loadRecaptchaScript="true"
      :sitekey="recaptchaKey"
      @verify="verify($event)"
      @expired="expired"
      @render="render($event)"
    ></vue-recaptcha>

    <sui-button
      content="Atras"
      float="left"
      type="button"
      @click="listOffers"
    />

    <sui-button
      color="green"
      content="Enviar"
      float="right"
      type="button"
      :disabled="isCreateButtonDisabled"
      @click="submit"
    />
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      captcha: null,
    }
  },
  methods: {
    ...mapActions('uploadForm', [ 'submit' ]),
    listOffers() {
      Turbolinks.visit('/offers')
    },
    verify(response) {
      this.$store.dispatch('uploadForm/setRecaptchaResponse', response)
    },
    expired() {
      this.$store.dispatch('uploadForm/setRecaptchaResponse', null)
    },
    render(id) {
      //console.debug('render: id =', id)
    },
  },
  computed: {
    ...mapGetters('uploadForm', [ 'isCreateButtonDisabled' ]),
    ...mapState('account', [ 'isAdmin' ]),
    ...mapState([ 'recaptchaKey' ]),
  },
}
</script>
