<template>
  <article class="selected-materials">
    <table class="ui table">
      <thead>
        <tr>
          <th scope="col">Cantidad</th>
          <th scope="col">Principal</th>
          <th scope="col">Secundario</th>
          <th scope="col" v-if="showConditions">Condición</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o in selectedMaterials" :key="o.id">
          <td>{{ o.qty }} Kg.</td>
          <td>{{ o.main }}</td>
          <td>{{ o.secondary }}</td>
          <td v-if="showConditions">
            {{ o.conditions }}

            <sui-label class="mr-1" v-for="feature in o.feature_list" :key="feature">
              {{ feature }}
            </sui-label>
          </td>
          <td>
            <sui-button
              type="button"
              color="red"
              icon="close"
              @click="$emit('delete', o)"
              :disabled="o.locked"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </article>
</template>

<script>
export default {
  props: {
    showConditions: { type: Boolean, required: false, default: true },
    selected: { type: Array, required: true },
  },

  computed: {
    /**
     * Filter out `_destroy`ed materials
     */
    selectedMaterials() {
      return this.selected.filter(el => !el._destroy)
    },
  },
}
</script>
