import http from '@/services/http'

const AccountStore = {
  namespaced: true,

  state: {
    isAdmin: false,
  },

  mutations: {
    setAdmin(state, isAdmin) {
      state.isAdmin = !!isAdmin
    },
  },

  actions: {
    setAdmin({ commit }, isAdmin) {
      commit('setAdmin', isAdmin)
    },
  },

  getters: {
    isAdmin: state => { return state.isAdmin },
  },
}

export { AccountStore }
