<template>
  <nav id="offers-filters">
    <offers-filters
      :query-string="queryString"
      :url="url"
    />
  </nav>
</template>

<script>
import OffersFilters from '@/components/OffersFilters.vue'

export default {
  name: "offersFiltersApp",
  components: {
    OffersFilters
  },
  data() {
    return {
      queryString: this.$root.$data.qs,
      url: this.$root.$data.url,
    }
  }
}
</script>
