<template>
  <div :class="componentClasses">
    <div class="ui fluid icon input">
      <input
        class="prompt border-2!"
        placeholder="Dirección"
        autocomplete="off"
        v-model="query"
        @keydown.down="down"
        @keydown.up="up"
        @keydown.enter="hit"
        @keydown.esc="reset"
        @input="(query.length > minChars) ? typeahead($event) : null"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <sui-icon name="search" />
    </div>

    <ul
      class="address-autocomplete-results"
      v-if="hasSuggestions"
      v-show="isFocused"
    >
      <li
        v-for="(item, i) in suggestions"
        :class="activeClass(i)"
        @mousedown="hit"
        @mousemove="setActive(i)"
      >
        <span v-text="item.customLabel"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import http from '@/services/http'

export default {
  props: {
    minChars: { type: Number, required: false, default: 5, },
    selectFirst: { type: Boolean, required: false, default: false, },
  },

  data () {
    return {
      current: -1,
      isFocused: false,
      query: '',
      timeoutId: null,
    }
  },

  computed: {
    ... mapState('addresses', [
      'suggestions',
      'isFetchingSuggestions',
    ]),

    hasSuggestions() {
      return this.suggestions && this.suggestions.length > 0
    },

    isEmpty() {
      return !this.query
    },

    isDirty() {
      return !!this.query
    },

    componentClasses() {
      return {
        loading: this.isFetchingSuggestions,
        'address-suggestions ui search': true,
      }
    },
  },

  methods: {
    ... mapActions('addresses', [ 'searchAddress' ]),

    typeahead(val) {
      if (this.query.length > this.minChars) {
        if (this.timeoutId) {
          window.clearTimeout(this.timeoutId)
        }

        this.timeoutId = window.setTimeout(() => {
          this.searchAddress(this.query)
        }, 300)
      }
    },

    reset() {
      this.$store.dispatch('addresses/resetSuggestions')
      this.$store.dispatch('addresses/setFetchingSuggestions')
    },

    setActive(index) {
      this.current = index
    },

    activeClass(index) {
      return {
        result: true,
        active: this.current === index,
      }
    },

    hit() {
      if (this.current !== -1) {
        this.$emit('selected', this.suggestions[this.current])
      }
    },

    up() {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.suggestions.length - 1
      } else {
        this.current = -1
      }
    },

    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current++
      } else {
        this.current = -1
      }
    },
  },
}
</script>

<style lang="scss">
$b-radius: 3px;
$b-color: #96c8da;

.address-autocomplete {
  &-results {
    width: 100%;
    max-height: 14rem;
    padding: 0;
    margin: -2px 0;
    list-style: none;
    border: 1px solid darken($b-color, 15%);
    border-top: none;
    border-radius: 0 0 $b-radius $b-radius;
    background-color: #fff;

    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    display: block;
    transition: opacity .1s ease;
    z-index: 11;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1em;

    .result {
      max-width: calc(100% + 2px);
      height: auto;
      position: relative;
      display: block;
      overflow: hidden;
      cursor: pointer;
      font-size: 0.9em;
      line-height: 1em;
      word-break: break-word;
      word-wrap: normal;
      white-space: normal;
      padding: 0.75em 1em;
      margin: 0;
      z-index: 13;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.95);
      }

      &.active {
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
