<template>
  <article class="here-map">
    <loader v-if="starting" />
    <div class="map-instance" ref="mapRef"></div>

    <aside class="mb-2 text-small" ref="panelRef">
    </aside>

    <footer class="my-2">
      <address-suggestions
        v-if="showSearch"
        @selected="suggestionSelected($event)"
      />

      <ul class="small" v-if="longPress">
        <li>Haz un click largo para seleccionar un punto</li>
        <li v-if="radius !== null">Especifica un radio para dibujar un area de busqueda</li>
      </ul>
    </footer>
  </article>
</template>

<script>
import Vue from 'vue'
import PROPERTIES from '@/config/properties'
import { MapService, ICONS_OPTS } from '@/services/map_service'
import AddressSuggestions from '@/components/here/AddressSuggestions'
import Loader from '@/components/Loader'
import OfferBubble from '@/components/here/OfferBubble'
import markerSvg from '@/components/here/icons/marker'
import { http, routes } from '@/services/http'

/**
 * @define type LonLat Array<Number>
 */

export default {
  components: {
    AddressSuggestions,
    Loader,
  },

  props: {
    apiKey: { type: String, required: true, },
    center: { type: Object, required: false, default() { return PROPERTIES.HERE_DEFAULT_CENTER } },

    /**
     * Zoom level used when map is loaded
     *
     * @type {number}
     */
    defaultZoom: { type: Number, required: false, default: 12, },

    /**
     * @type LonLat
     */
    from: { type: Array, required: false },
    lat: { type: Number, required: false, default: null, },
    lon: { type: Number, required: false, default: null, },
    longPress: { type: Boolean, default: false },
    loadTimeout: { type: Number, default: 3000 }, // Loading timeout in milliseconds
    radius: { type: Number, required: false, default: null, },
    showSearch: { type: Boolean, default: true, },

    /**
     * @type Array<Object>
     */
    points: { type: Array, required: false, default: () => [] },

    /**
     * @type LonLat
     */
    to: { type: Array, required: false },

    /**
     * Keep objects after a `longpress` event is triggered.
     */
    keepObjects: { type: Boolean, default: false, },
  },

  data() {
    return {
      icons: {},
      platform: null,
      map: null,
      mapEvents: null,
      behavior: null,
      mapObjects: {
        bubbles: [],
        circles: [],
        markers: [],
        routes: [],
      },
      mapService: null,
      point: {},
      starting: true,
      ui: null,
      groups: {},
    }
  },

  watch: {
    from(newVal, oldVal) {
      if (this.to) {
        this.drawRoute(this.from, this.to)
      }
    },

    to(newVal, oldVal) {
      if (this.from) {
        this.drawRoute(this.from, this.to)
      }
    },

    points(newVal, oldVal) {
      this.drawMarkers()
    },
  },

  /**
   * Setup HERE Platform
   */
  created() {
    if (window.H) {
      let icon = null,
        iconConf = null,
        iconName = null,
        opt = null

      for (iconName in ICONS_OPTS) {
        iconConf = ICONS_OPTS[iconName]
        icon = (' ' + markerSvg).slice(1)

        for (opt in iconConf) {
          icon = icon.replaceAll(`{${opt}}`, iconConf[opt])
        }

        this.icons[iconName] = new H.map.Icon(icon)
      }

      this.platform = new H.service.Platform({ apikey: this.apiKey, })
    } else {
      console.error('Could not load Here map: H is undefined')
    }
  },

  /**
   * Visual components **MUST** be initialized after the component has
   * been mounted/rendered.
   *
   * We use a timeout because the map tiles loading fails whenever the user
   * visits a page with this component first, probably because the loading of
   * the HERE assets is not ready.
   */
  mounted() {
    window.setTimeout((ev) => {
      if (this.platform && this.$refs.mapRef) {
        let layers = this.platform.createDefaultLayers()
        let opts = {
          center: this.center,
          zoom: this.defaultZoom,
        }

        this.map = new H.Map(this.$refs.mapRef, layers.vector.normal.map, opts)
        this.mapEvents = new H.mapevents.MapEvents(this.map)
        this.behavior = new H.mapevents.Behavior(this.mapEvents)
        this.ui = H.ui.UI.createDefault(this.map, layers)
        this.mapService = new MapService(this.map, this.ui, this.$refs.panelRef)
        this.groups.markers = new H.map.Group()

        this.map.addObject(this.groups.markers)

        // add 'tap' event listener, that opens info bubble, to the group
        this.groups.markers.addEventListener('tap', (ev) => {
          if (!ev.target.getData()) return null

          // event target is the marker itself, group is a parent event target
          // for all objects that it contains
          http.get(routes.adminOffer(ev.target.getData().id))
            .then(resp => {
              const OfferBubbleClass = Vue.extend(OfferBubble)
              const offerBubble = new OfferBubbleClass({ propsData: { offer: resp.data } })
              offerBubble.$mount()

              this.addBubble(ev.target.getGeometry(), offerBubble.$el)
            })
            .catch(err => {
              console.error('Could not fetch offer details', err)
            })
        }, false)

        if (this.longPress)
          this.map.addEventListener('longpress', this.handleLongPress)

        if (this.lon && this.lat && this.radius) {
          this.drawCircle({ lng: this.lon, lat: this.lat }, this.radius)
        }

        this.drawMarkers()
      } else {
        console.error('Failed to initialize map.')
      }

      // hide loader
      this.starting = false
    }, this.loadTimeout)
  },

  methods: {
    /**
     * Add marker to map
     *
     * @param {HerePoint} coords
     */
    addMarker(coords, opts, html = null) {
      if (this.map) {
        let marker = new H.map.Marker(coords, opts)

        if (html) {
          marker.setData(html)
        }

        if (!this.keepObjects && this.mapObjects.markers.length > 0) {
          this.map.removeObjects(this.mapObjects.markers)
          this.mapObjects.markers = []
        }

        this.groups.markers.addObject(marker)
        this.mapObjects.markers.push(marker)
      }
    },

    /**
     * Add text bubble at the specified coordinates
     *
     * @see HERE UI https://developer.here.com/documentation/maps/api_reference/H.ui.UI.html
     * @see Bubble examples https://developer.here.com/documentation/examples/maps-js/infobubbles/open-infobubble
     */
    addBubble(coords, text) {
      if (this.map) {
        let bubble = new H.ui.InfoBubble(coords, { content: text }),
          el = null

        if (!this.keepObjects && this.mapObjects.bubbles.length > 0) {
          while (el = this.mapObjects.bubbles.shift()) {
            this.ui.removeBubble(el)
          }
        }

        if (this.radius > 0) {
          bubble.addEventListener('click', (ev) => {
            this.drawCircle(coords, this.radius)
          })
        }

        this.ui.addBubble(bubble)
        this.mapObjects.bubbles.push(bubble)
      }
    },

    /**
     * Remove specified objects from map.
     *
     * @param {string} objectType One of ['markers', 'routes']
     */
    clearMapObjects(objectType) {
      if (objectType && this.mapObjects[objectType].length > 0) {
        if (objectType === 'markers') {
          this.groups.markers.removeObjects(this.groups.markers.getObjects())
        } else {
          this.map.removeObjects(this.mapObjects[objectType])
        }
        this.mapObjects[objectType] = []
      }
    },

    drawRoute(from, to) {
      const routeParams = {
        routingMode: 'fast',
        transportMode: 'truck',
        origin: `${from[1]},${from[0]}`,
        destination: `${to[1]},${to[0]}`,
        return: 'polyline,travelSummary',
        spans: 'truckAttributes',
      }

      this.platform.getRoutingService(null, 8)
        .calculateRoute(routeParams,
          (resp) => {
            if (this.mapObjects.routes.length > 0) {
              this.clearMapObjects('markers')
              this.clearMapObjects('routes')
            }

            this.mapObjects.markers.push(this.mapService.newSVGMarker(from, 'O'))
            this.mapObjects.markers.push(this.mapService.newSVGMarker(to, 'D'))
            this.mapObjects.routes.push(this.mapService.drawRoute(resp))
          },
          (err) => { console.error('could not draw error', err) }
        )
    },

    /**
     * Draw circle at the specified point with the specified radius
     *
     * @see https://developer.here.com/documentation/maps/api_reference/H.map.Circle.html
     */
    drawCircle(point, radius) {
      if (this.map) {
        let circle = new H.map.Circle(point, radius)

        if (!this.keepObjects && this.mapObjects.circles.length > 0) {
          this.map.removeObjects(this.mapObjects.circles)
          this.mapObjects.circles = []
        }

        this.map.addObject(circle)
        this.mapObjects.circles.push(circle)
      }
    },

    /**
     * Handle map's longpress event
     *
     * TODO: get location info and add bubble with address label
     *
     * @see https://developer.here.com/documentation/maps/api_reference/H.Map.html#screenToGeo
     * @emit longpress({ lng: Number, lat: Number })
     */
    handleLongPress(ev) {
      let point = this.map.screenToGeo(
        ev.currentPointer.viewportX,
        ev.currentPointer.viewportY
      )

      if (this.radius) {
        this.drawCircle(point, this.radius)
      }
      this.$emit('longpress', point)
      this.addMarker(point)
      this.point = point
    },

    /**
     * Handle suggestion `click` event
     *
     * @see SearchService API reference: https://developer.here.com/documentation/maps/3.1.24.0/api_reference/H.service.SearchService.html
     */
    suggestionSelected(s) {
      const params = {
        q: s.label,
      }

      this.platform.getSearchService().geocode(params, (resp) => {
        let item = resp.items[0]

        this.addBubble(item.position, item.address.label)

        this.map.setCenter(item.position)
        this.map.setZoom(PROPERTIES.ZOOM_ON_SELECTION)
        this.$emit('suggestionSelected', item)
      }, (err) => {
        console.error('suggestionSelected:', err)
      })
    },

    drawMarkers() {
      if (this.loading) return

      this.clearMapObjects('markers')
      this.clearMapObjects('routes')
      if (this.points.length > 0) {
        this.points.forEach((point, i) => {
          this.addMarker(
            point.point,
            { icon: this.icons[point.icon] },
            { id: point.id }
          )
        })
      }
    },
  },

}
</script>

<style scoped lang="scss">
.here-map {
  .address-suggestions {
    max-width: 99%;
    margin-bottom: 0.2em;
  }

  .map-instance {
    width: 99%;
    height: 60vh;
    min-height: 15em;
    background-color: silver;
  }
}
</style>
