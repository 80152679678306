<template>
  <sui-form
    action="#"
    @submit.stop.prevent="renderButton"
    class="paypal-checkout"
  >
    <div class="fields">
      <div class="six wide field">
        <label for="paypal-price">Créditos</label>
        <sui-dropdown
          id="paypal-price"
          v-model="price"
          selection
          :options="bundleOptions"
          @input="renderButton"
        />
      </div>

      <div class="ten wide field">
        <p
          :class="{ 'paypal-checkout-message': true, error: result.error, success: result.success }"
          v-if="result.message"
        >
          {{ result.message }}.
          <span v-if="result.error">
            Si el problema persiste, por favor contacte al equipo de Supraciclaje.
          </span>
        </p>
        <div
          ref="paypalContainer"
          id="paypal_checkout_container"
        ></div>
      </div>
    </div>
  </sui-form>
</template>

<script>
import { bundleOptions, creditBundles, } from '@/services/payments'
import http from '@/services/http'

export default {
  data() {
    return {
      price: null,
      result: {
        error: false,
        message: null,
        success: false,
      },
    }
  },

  methods: {
    createPaypalOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: this.price,
            },
          },
        ]
      })
    },

    orderApproved(data, actions) {
      // capture the funds from the transaction
      return actions.order.capture()
        .then((details) => {
          const payload = {
            amount: this.price,
            payment: data,
          }

          return http.post(`/payments/paypal/${data.orderID}`, payload)
        })
        .then((resp) => {
          this.destroyButton()
          this.setSuccessMessage('Su pago ha sido procesado exitosamente')
          window.setTimeout(() => { window.location.reload() }, 3500)
        })
        .catch((err, resp) => {
          if (err.response && err.response.data) {
            this.setErrorMessage(err.response.data.error)
          } else {
            this.setErrorMessage('Ocurrió un error al procesar su pago')
          }
          console.error('error with paypal payment:', err)
        })
    },

    destroyButton() {
      this.$refs.paypalContainer.childNodes.forEach((el) => {
        this.$refs.paypalContainer.removeChild(el)
      })
    },

    renderButton() {
      this.destroyButton()
      this.clearResult()

      if (window.paypal) {
        paypal.Buttons({
          createOrder: this.createPaypalOrder,
          onApprove: this.orderApproved,
        }).render('#paypal_checkout_container')
      } else {
        this.setErrorMessage('Ocurrió un error al cargar PayPal')
      }
    },
    clearResult() {
      this.result.message = null
      this.result.error = this.result.success = false
    },
    setErrorMessage(msg) {
      this.result.message = msg
      this.result.error = true
      this.result.success = false
    },
    setSuccessMessage(msg) {
      this.result.message = msg
      this.result.error = false
      this.result.success = true
    },
  },
  computed: {
    bundleOptions,
  },
  mounted() {
  },
}
</script>

<style lang="scss">
$sui-green: #21ba45;
$sui-red: #db2828;

.paypal-checkout {
  &-message {
    text-align: center;
    color: #fff;
    padding: 0.3rem;
    margin: 0.2rem 1rem 0;
    border-radius: 0.6rem;

    &.error {
      background-color: $sui-red;
    }
    &.success {
      background-color: $sui-green;
    }
  }
}
</style>
