<template>
  <sui-accordion class="offers-filters" exclusive styled>
    <sui-accordion-title :active="filtersOpen">
      <sui-icon name="dropdown" />
      Filtros
    </sui-accordion-title>

    <sui-accordion-content :active="filtersOpen">
      <div class="ui form">
        <div class="fields">
          <div class="three wide field">
            <label for="offer_type_filter">Tipo de Oferta</label>
            <sui-dropdown
              placeholder="Tipo de Oferta"
              id="offer_type_filter"
              class="offer-types"
              selection
              :options="offerTypesOptions"
              v-model="offerType"
            />
          </div>

          <div class="six wide field" v-if="materialOptions.length > 0">
            <label for="materials_filter">Materiales</label>
            <div class="two fields">
              <div class="field">
                <sui-dropdown
                  placeholder="Materiales"
                  id="materials_filter"
                  selection
                  multiple
                  search
                  :options="materialOptions"
                  v-model="selectedMaterials"
                />
              </div>

              <div class="field">
                <sui-dropdown
                  placeholder="Submateriales"
                  id="submaterials_filter"
                  selection
                  multiple
                  search
                  :options="submaterialOptions"
                  :disabled="submaterialOptions.length < 1"
                  v-model="selectedSubmaterials"
                />
              </div>
            </div>
          </div>

          <div class="three wide field" v-if="isAdmin">
            <label for="status_filter">Status</label>
            <sui-dropdown
              placeholder="Status"
              class="offer-statuses"
              id="status_filter"
              selection
              :options="statusOptions"
              v-model="offerStatus"
            />
          </div>

          <div class="four wide field">
            <label for="qty_filter">Cantidad</label>
            <div class="fields">
              <div class="seven wide field">
                <sui-input
                  placeholder="Cantidad"
                  type="number"
                  step="any"
                  min="1"
                  id="qty_filter"
                  name="qty"
                  required
                  v-model.number="qty"
                />
              </div>

              <div class="nine wide field">
                <sui-dropdown
                  class="weight-units-dropdown no-min-width"
                  id="weight_unit_filter"
                  labeled
                  :options="weightUnits"
                  selection
                  text="Unidades"
                  v-model="weightUnit"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="fields">
          <div class="seven wide field">
            <label>Ubicación</label>
            <div class="two fields">
              <div class="eight wide field">
                <sui-dropdown
                  placeholder="País"
                  selection
                  :options="countriesOptions"
                  v-if="countriesOptions.length > 0"
                  v-model="countryId"
                />
              </div>

              <div class="eight wide field">
                <sui-dropdown
                  placeholder="Estados"
                  selection
                  multiple
                  search
                  :options="statesOptions"
                  v-if="statesOptions.length > 0"
                  v-model="selectedStates"
                />
              </div>
            </div>
          </div>

          <div class="six wide field">
            <label>Fecha de Publicación</label>
            <div class="two fields">
              <div class="field">
                <sui-input
                  placeholder="Fecha Inicio"
                  type="date"
                  name="start_date"
                  v-model="startDate"
                />
              </div>

              <div class="field">
                <sui-input
                  placeholder="Fecha Fin"
                  type="date"
                  name="end_date"
                  v-model="endDate"
                />
              </div>
            </div>
          </div>

          <div class="three wide field">
            <label>Ordenar por</label>
            <sui-dropdown
              id="sort_by_filter"
              class="sort-by"
              selection
              :options="sortByOptions"
              v-model="sortBy"
            />
          </div>
        </div>

        <div class="fields">
          <div class="four wide field">
            <label for="contact_filter">Contacto</label>
            <sui-input
              placeholder="Email"
              id="contact_filter"
              class="offer-contact"
              v-model="contact"
            />
          </div>

          <div class="four wide field">
            <label for="description_filter">Descripción</label>
            <sui-input
              id="description_filter"
              class="offer-description-filter"
              v-model="description"
            />
          </div>
        </div>

        <div class="actions text-right">
          <a class="mini ui green button" :href="actionUrl">
            Filtrar
          </a>
        </div>
      </div>
    </sui-accordion-content>
  </sui-accordion>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

/**
 * This is a mess.
 *
 * TODO: organize the whole offers-filters feature
 */
export default {
  name: 'OffersFilters',

	components: {
	},

  props: {
    queryString: { type: Object, default: {} },
    url: { type: String, default: '' }
  },

  data() {
    return {
      contact: null,
      countryId: null,
      description: null,
      endDate: null,
      filtersOpen: true,
      offerStatus: 0,
      offerType: null,
      selectedMaterials: [],
      selectedSubmaterials: [],
      selectedStates: [],
      sortBy: null,
      startDate: null,
      qty: 1,
      weightUnit: 1,
    }
  },

  computed: {
    ...mapState([ 'weightUnits' ]),
    ...mapState('materials', [ 'materials' ]),
    ...mapGetters('account', [ 'isAdmin' ]),
    ...mapGetters('addresses', [ 'statesOptions', 'countriesOptions' ]),
    ...mapGetters('materials', [ 'materialOptions' ]),
    ...mapGetters('offers', [
      'offerStatusesOptions',
      'offerTypesOptions',
      'sortByOptions',
    ]),

    submaterialOptions() {
      const list = []

      for (const i in this.materials) {
        if (this.selectedMaterials.includes(this.materials[i].main)) {
          list.push({
            text: this.materials[i].secondary,
            value: this.materials[i].secondary
          })
        }
      }

      return list
    },

    statusOptions() {
      const list = []

      this.offerStatusesOptions.forEach(el => list.push(el))
      list.unshift({ text: 'Todos', value: 0 })

      return list
    },

    actionUrl() {
      let quantity = 1
      const filters = []
      const encode = window.encodeURIComponent

      if (!isNaN(this.qty) && !isNaN(this.weightUnit)) {
        quantity = this.qty * this.weightUnit
      }

      this.selectedMaterials.forEach(el => filters.push(`materials[]=${encode(el)}`))
      this.selectedSubmaterials.forEach(el => filters.push(`submaterials[]=${encode(el)}`))
      this.selectedStates.forEach(val => filters.push(`states[]=${encode(val)}`))

      if (this.countryId)
        filters.push(`country_id=${this.countryId}`)
      if (this.offerStatus)
        filters.push(`status=${this.offerStatus}`)
      if (this.offerType)
        filters.push(`offer_type=${this.offerType}`)
      if (this.startDate)
        filters.push(`start_date=${this.startDate}`)
      if (this.endDate)
        filters.push(`end_date=${this.endDate}`)
      if (this.sortBy)
        filters.push(`sort=${this.sortBy}`)

      if (quantity > 1)
        filters.push(`qty=${quantity}`)
      if (this.contact)
        filters.push(`contact=${this.contact}`)
      if (this.description)
        filters.push(`description=${this.description}`)

      return `${this.url}?${filters.join('&')}`
    },
  },

  watch: {
    // @change was not working properly, this will do
    countryId(newVal, oldVal) {
      this.selectedStates = []
      this.$store.dispatch('addresses/updateStates', newVal)
    },
  },

  /**
   * Set current filter values
   *
   * **NOTE**: data type is important, if <sui-option> has a Number, give it
   * a Number!
   */
  mounted() {
    if (Object.keys(this.queryString).length > 0) {
      this.filtersOpen = true
    }

    if (this.queryString.qty)
      this.qty = Number(this.queryString.qty)

    if (this.queryString.start_date)
      this.startDate = this.queryString.start_date
    if (this.queryString.end_date)
      this.endDate = this.queryString.end_date

    if (this.queryString.states) {
      this.queryString.states.forEach((el) => {
        this.selectedStates.push(Number(el))
      })
    }

    if (this.queryString.materials) {
      this.queryString.materials.forEach((el) => {
        this.selectedMaterials.push(el)
      })
    }
    if (this.queryString.submaterials) {
      this.queryString.submaterials.forEach((el) => {
        this.selectedSubmaterials.push(el)
      })
    }

    if (this.queryString.country_id) {
      this.countryId = Number(this.queryString.country_id)
    }

    if (this.queryString.offer_type)
      this.offerType = this.queryString.offer_type
    if (this.queryString.status)
      this.offerStatus = this.queryString.status
    if (this.queryString.contact)
      this.contact = this.queryString.contact
    if (this.queryString.description)
      this.description = this.queryString.description
    if (this.queryString.sort)
      this.sortBy = this.queryString.sort
  }
}
</script>

<style lang="scss">
.offers-filters {
  width: 100% !important;

  // overrides
  .content {
    padding-bottom: 1em !important;
  }
}
</style>
