<template>
  <sui-grid
    class="upload-form"
    :doubling="true"
    :stackable="true"
    id="upload-form"
  >
    <h2>Selecciona los materiales</h2>

    <sui-grid-row :columns="2">
      <sui-grid-column>
        <selected-materials
          @delete="removeSelectedMaterial($event)"
          @edit="setMaterialFormData($event)"
          :selected="selected"
        />
      </sui-grid-column>

      <sui-grid-column>
        <materials-form @input="addSelectedMaterial($event)" />
      </sui-grid-column>
    </sui-grid-row>

    <sui-grid-row :columns="2">
      <sui-grid-column>
        <h2>Indica la ubicación del material</h2>
        <address-form />
      </sui-grid-column>

      <sui-grid-column>
        <h2>Información adicional</h2>
        <additional-information />
      </sui-grid-column>
    </sui-grid-row>

    <sui-grid-row :columns="2">
      <sui-grid-column>
        <h2>Información de contacto</h2>
        <contact-information />
      </sui-grid-column>

      <sui-grid-column>
        <h2>Fotos</h2>
        <images />
      </sui-grid-column>
    </sui-grid-row>

    <sui-grid-row :columns="2">
      <sui-grid-column>
        <sui-list
          bulleted
          class="offer-errors ui red segment ml-0 py-2 pl-8"
          v-if="errors && errors.length > 0"
        >
          <span
            is="sui-list-item"
            class="offer-error my-2"
            v-for="(message, idx) in errors"
            :key="idx"
          >
            <sui-list-content>
              <sui-list-description>
                {{ message }}
              </sui-list-description>
            </sui-list-content>
          </span>
        </sui-list>
      </sui-grid-column>

      <sui-grid-column>
        <submit-actions />
      </sui-grid-column>
    </sui-grid-row>
  </sui-grid>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdditionalInformation from '@/components/forms/upload/AdditionalInformation.vue'
import AddressForm from '@/components/forms/upload/AddressForm.vue'
import ContactInformation from '@/components/forms/upload/ContactInformation.vue'
import SubmitActions from '@/components/forms/upload/Actions.vue'
import MaterialsForm from '@/components/MaterialsForm.vue'
import Images from '@/components/forms/upload/Images.vue'
import SelectedMaterials from '@/components/SelectedMaterials.vue'

export default {
  name: 'UploadForm',

  components: {
    AdditionalInformation,
    AddressForm,
    ContactInformation,
    MaterialsForm,
    Images,
    SelectedMaterials,
    SubmitActions,
  },

  methods: {
    ...mapActions('uploadForm', [ 'addSelectedMaterial', 'removeSelectedMaterial' ]),

    setMaterialFormData(material) {
      console.debug('UploadForm#setMaterialFormData: material', material)
    },
  },

  computed: {
    ...mapState({
      selected: state => state.uploadForm.form.materials,
      errors: state => state.uploadForm.offerErrors,
    }),
  },
}
</script>
