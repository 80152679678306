<template>
  <section class="add-credits-modal">
    <sui-button
      type="button"
      size="small"
      color="green"
      @click.native="toggle"
    >
      <i class="plus circle icon"></i>
      Agregar créditos
    </sui-button>

    <sui-modal size="small" v-model="open">
      <header class="header">Agregar Créditos</header>
      <article class="content scrolling">
        <paypal-checkout />
      </article>
      <sui-modal-actions>
        <sui-button
          type="button"
          @click.native="toggle"
        >
          Cerrar
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </section>
</template>

<script>
import PaypalCheckout from '@/components/payments/PaypalCheckout.vue'

export default {
  components: {
    PaypalCheckout,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
  computed: {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.add-credits-modal {
  .ui.modal {
    .content {
      min-height: 17rem !important;
    }
  }
}
</style>
