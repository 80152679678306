export const COLORS = {
  primary: '#21ba45',

  green: '#21ba45',

  offerType: {
    buy: '#a333c8',
    sell: '#2185d0',
  },
}

export default {
  USE_CACHE: true,
  CACHE_MESSAGE: 'Using cache. If you want the most recent results, refresh the page.',
  HERE_DEFAULT_CENTER: { lng: -100.307725, lat: 25.665346, },
  ZOOM_ON_SELECTION: 14,

  COLORS: COLORS,
}
