export default `
<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
    <!--<rect stroke="white" fill="{COLOR}" x="1" y="1" width="22" height="22" />-->
    <circle stroke-width="1" stroke="white" fill="{COLOR}" cx="9" cy="9" r="8" />
    <text
        x="9"
        y="13"
        font-size="8pt"
        font-family="sans-serif"
        font-weight="normal"
        text-anchor="middle"
        fill="white"
    >{TEXT}</text>
</svg>
`.replaceAll('\n', '')
