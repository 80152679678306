import { render, staticRenderFns } from "./AdditionalInformation.vue?vue&type=template&id=72f8b05c&scoped=true&"
import script from "./AdditionalInformation.vue?vue&type=script&lang=js&"
export * from "./AdditionalInformation.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalInformation.vue?vue&type=style&index=0&id=72f8b05c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f8b05c",
  null
  
)

component.options.__file = "AdditionalInformation.vue"
export default component.exports