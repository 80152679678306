import http from '@/services/http'

const SubscriptionsStore = {
  namespaced: true,

  state: {
    company_id: null,
    subscription: {
      id: null,
      city: null,
      state_id: null,
      country_id: null,
      radius: 0,
      lat: null,
      lon: null,
    },
  },

  mutations: {
    /**
     * Submit subscription
     */
    submit(state) {
      const data = {
        company_subscription: state.subscription,
      }
      let url = `/companies/${state.company_id}/subscriptions`,
        promise = null

      if (state.subscription.id) {
        promise = http.put(`${url}/${state.subscription.id}`, data)
      } else {
        promise = http.post(url, data)
      }

      promise
        .then((resp) => {
          return resp.data
        })
        .then((data) => {
          if (data.view) {
            Turbolinks.visit(data.view)
          } else {
            console.info('subscriptions/submit: no view to redirect to')
            window.alert('Suscripcion guardada')
            window.location.href = '/'
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },

    setCompanyId(state, companyId) { state.company_id = companyId },
    setSubscriptionId(state, id) { state.subscription.id = id },
    setSubscriptionCity(state, city) { state.subscription.city = city },
    setSubscriptionState(state, stateId) { state.subscription.state_id = stateId },
    setSubscriptionCountry(state, countryId) { state.subscription.country_id = countryId },
    setSubscriptionRadius(state, radius) { state.subscription.radius = radius },
    setSubscriptionLat(state, lat) { state.subscription.lat = lat },
    setSubscriptionLon(state, lon) { state.subscription.lon = lon },
  },

  actions: {
    submit({ commit }) { commit('submit') },
    setCompanyId({ commit }, companyId) { commit('setCompanyId', Number(companyId)) },
    setSubscriptionId({ commit }, id) { commit('setSubscriptionId', id) },
    setSubscriptionCity({ commit }, city) { commit('setSubscriptionCity', city) },
    setSubscriptionState({ commit }, stateId) { commit('setSubscriptionState', stateId) },
    setSubscriptionCountry({ commit }, countryId) { commit('setSubscriptionCountry', countryId) },
    setSubscriptionRadius({ commit }, radius) { commit('setSubscriptionRadius', radius) },
    setSubscriptionLat({ commit }, lat) { commit('setSubscriptionLat', lat) },
    setSubscriptionLon({ commit }, lon) { commit('setSubscriptionLon', lon) },
  },

  getters: {
  },
}

export { SubscriptionsStore }
