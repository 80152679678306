/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'
import SuiVue from 'semantic-ui-vue';
import TurbolinksAdapter from 'vue-turbolinks'
import Swiper from 'swiper'
import Turbolinks from 'turbolinks'
import filters from '@/filters'
import store from '@/stores'
import applications from '@/apps'
import $ from 'jquery'

// import styles
import 'semantic-ui-css/semantic.min.css'
import 'select2/dist/css/select2.min.css'
import 'swiper/dist/css/swiper.min.css'

Turbolinks.start()

Vue.config.productionTip = false
Vue.use(SuiVue);
Vue.use(TurbolinksAdapter)

// register vue filters
for (const k in filters) {
	Vue.filter(k, filters[k])
}

const startApps = (ev) => {
  let app = null
  let container = null
  let k = null

  for (k in applications) {
    app = applications[k]
    container = document.querySelector(app.selector)

    if (container) {
      new Vue({
        store,
        data: () => app.data(container),
        el: container,
        render: h => h(app.component, { props: app.props ? app.props(container) : {} }),
        created() {
          app.created.call(this, container)
        },
      })
    }
  }

  // swiper
  let offerSwiper = new Swiper('.swiper-container', {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  })
}

window.addEventListener('turbolinks:load', startApps)
