import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=113ef087&scoped=true&"
var script = {}
import style0 from "./Loader.vue?vue&type=style&index=0&id=113ef087&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113ef087",
  null
  
)

component.options.__file = "Loader.vue"
export default component.exports