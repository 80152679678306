import http from '@/services/http'

const CompaniesStore = {
  state: {
    companies: [],
    company: null
  },

  mutations: {
    setCompany(state, company) {
      state.company = company
    },
    updateCompanies(state) {
      http.get('/companies.json')
        .then((resp) => {
          state.companies = resp.data
        })
        .catch((err) => {
          console.error('updateCompanies:', err)
          throw err
        })
    }
  },

  actions: {
    getCompanies({ commit }) {
      commit('updateCompanies')
    },
    setCompany({ commit, dispatch }, companyId) {
      commit('setCompany', companyId)
      dispatch('addresses/setCompany', companyId, { root: true })
      dispatch('uploadForm/updateUploadFormCompany', companyId, { root: true })
    }
  },

  getters: {
    companiesOptions: state => {
      const list = []

      state.companies
        .forEach(el => {
          if (list.indexOf(el) === -1) {
            list.push(el)
          }
        })

      return list.map(el => {
        return {
          text: el.name,
          value: el.id
        }
      })
    }
  }
}

export { CompaniesStore }
