<template>
  <sui-container id="app">
    <UploadForm />
  </sui-container>
</template>

<script>
import UploadForm from '@/components/UploadForm.vue'

export default {
  name: 'offerForm',
  components: {
    UploadForm
  }
}
</script>
