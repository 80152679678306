// NUMBER FILTERS
const UNITS = {
  ton: "Tons",
  kg: "Kg.",
  km: "Km.",
  m: "m.",
};

/**
 * Convert Kilograms to Tons
 *
 * @param {Number} n
 * @return {Number} Tons
 */
function kg2ton(n) {
  return n / 1000;
}

/**
 * Convert meters to kilometers
 *
 * @param {Number} n
 * @return {Number} Kilometers
 */
function m2km(n) {
  return n / 1000
}

/**
 * Distance readable format with meter-based unit.
 *
 * Return at most 2 decimal numbers, or none if number is rounded
 *
 * @param {Number} n In kilograms
 * @return {String} Amount with weight unit
 */
export function distance(n) {
  let unit = UNITS.m,
    m = n

  if (n >= 1000) {
    unit = UNITS.km
    m = m2km(n).toFixed(2).replace(',', '.').replace(/\.00$/, '')
  }

  return `${m} ${unit}`
}

/**
 * Weight readable format with Kilogram-based unit.
 *
 * Return at most 2 decimal numbers, or none if number is rounded
 *
 * @param {Number} n In kilograms
 * @return {String} Amount with weight unit
 */
export function weight(n) {
  let unit = UNITS.kg;
  let w = n;

  if (n >= 1000) {
    unit = UNITS.ton;
    w = kg2ton(n).toFixed(2).replace(',', '.').replace(/\.00$/, '');
  }

  return `${w} ${unit}`;
}

export function currency(value) {
    let val = (value/1).toFixed(2).replace(',', '.')
    return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function toMMSS(duration) {
  return Math.floor(duration / 60) + ' minutos ' + (duration % 60) + ' segundos'
}

export default {
  currency: currency,
  distance: distance,
  toMMSS: toMMSS,
  weight: weight,
}
