import querystring from 'querystring'
import http from '@/services/http'
import PROPERTIES from '@/config/properties'
import { distance, toMMSS } from '@/filters'
import markerSvg from '@/components/here/icons/marker'

export const SUGGESTIONS_PATH = '/addresses/autocomplete'
export const SVG_MARKUP = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
  '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
  '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
  'text-anchor="middle" fill="${STROKE}">${TEXT}</text></svg>'

/**
 * Point type used by Here map
 *
 * @typedef {Object} HerePoint
 * @property {number} lng Geographic longitud
 * @property {number} lat Geographic latitude
 */
export const ICONS_OPTS = {
  buy: {
    COLOR: '#a333c8',
    TEXT: 'C',
  },
  sell: {
    COLOR: '#2185d0',
    TEXT: 'V',
  },
}

export class MapService {
  STYLES = {
    ROUTES: {
      line: {
        lineWidth: 4,
        strokeColor: 'rgba(0, 128, 255, 0.7)'
      },
    },
  }

  /**
   * @param {H.Map} map
   * @param {H.ui.UI} ui
   * @param {NodeElement} panel
   */
  constructor(map, ui, panel) {
    this.map = map
    this.ui = ui
    this.panel = panel
  }

  /**
   * Add text bubble at the specified coordinates
   *
   * @see HERE UI https://developer.here.com/documentation/maps/api_reference/H.ui.UI.html
   * @see Bubble examples https://developer.here.com/documentation/examples/maps-js/infobubbles/open-infobubble
   */
  addBubble(coords, text, center = false) {
    const bubble = new H.ui.InfoBubble(coords, { content: text })

    this.ui.addBubble(bubble)
    if (center) {
      this.map.setCenter(coords)
      this.map.setZoom(PROPERTIES.ZOOM_ON_SELECTION)
    }
  }

  /**
   * Draw route in map
   *
   * @param routesReponse Response from Here's Routing endpoint
   * @return {Here.map.Polyline} route line
   */
  drawRoute(routesResponse) {
    const route = routesResponse.routes[0]

    this.addSummaryToPanel(route)

    return this.addRouteShapeToMap(route)
  }

  addRouteShapeToMap(route) {
    let polyline = null

    // decode LineString from the flexible polyline
    route.sections.forEach((section) => {
      let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline)
      polyline = new H.map.Polyline(linestring, { style: this.STYLES.ROUTES.line })

      // Add the polyline to the map
      this.map.addObject(polyline)
      // And zoom to its bounding rectangle
      this.map.getViewModel().setLookAtData({
        bounds: polyline.getBoundingBox()
      })
    })

    return polyline
  }

  /**
   * Creates a series of H.map.Marker points from the route and adds them to the map.
   *
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  addSummaryToPanel(route) {
    let duration = 0,
      m = 0

    route.sections.forEach((section) => {
      m += section.travelSummary.length
      duration += section.travelSummary.duration
    })

    let content = '<b>Distancia total</b>: ' + distance(m) + '<br />' +
      '<b>Tiempo de viaje</b>: ' + toMMSS(duration) + ' (con trafico actual)';

    this.panel.innerHTML = content;
  }

  newSVGMarker(lonlat, text, fill = PROPERTIES.COLORS.primary, stroke = 'white') {
    const marker = new H.map.Marker(
      { lat: lonlat[1], lng: lonlat[0] },
      { icon: new H.map.Icon(
          SVG_MARKUP
            .replace('${TEXT}', text)
            .replace('${FILL}', fill)
            .replace('${STROKE}', stroke))
      }
    )

    this.map.addObject(marker)
    return marker
  }

  setupIcons() {
    const icons = {}
    let icon = null,
      iconConf = null,
      iconName = null,
      opt = null

    for (iconName in ICONS_OPTS) {
      iconConf = ICONS_OPTS[iconName]
      icon = (' ' + markerSvg).slice(1)

      for (opt in iconConf) {
        icon = icon.replaceAll(`{${opt}}`, iconConf[opt])
      }

      icons[iconName] = new H.map.Icon(icon)
    }

    return icons
  }

  /**
   * Get address suggestions from free-form input
   *
   * @param {string} searchText Free-form input text to look up
   * @return {null}
   */
  searchAddress(searchText) {
    const params = { query: searchText }
    const url = `${SUGGESTIONS_PATH}?${querystring.stringify(params)}`

    return http.get(url, params)
      .then((resp) => {
        return resp.data.suggestions.map((el) => {
          return {
            ...el,
            customLabel: this.genAddressCustomLabel(el.address)
          }
        })
      })
      .catch((err) => {
        console.error('searchAddress:', err)
        state.isFetchingSuggestions = false
      })
  }


  genAddressCustomLabel(a) {
    let s = ''

    if (a.street)
      s += a.street
    if (a.houseNumber)
      s += ` ${a.houseNumber}`
    if (s.length > 0)
      s += ','

    if (a.district)
      s += ` ${a.district},`
    if (a.city)
      s += ` ${a.city}`
    if (a.postalCode)
      s += ` ${a.postalCode}`
    s += ','

    if (a.state)
      s += ` ${a.state},`
    if (a.country)
      s += ` ${a.country}`

    return s
  }
}
