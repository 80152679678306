import http from '@/services/http'
import querystring from 'querystring'
import PROPERTIES from '@/config/properties'
import OFFER_STATUSES from '@/config/offer_statuses'
import OFFER_TYPES from '@/config/offer_types'

const OffersStore = {
  state: {
    offers: [],
    offerTypes: OFFER_TYPES,
    offerStatuses: OFFER_STATUSES,
  },

  mutations: {
    getOffers(state, filters = {}) {
      // rails does this, so let's do it too
      filters.utf8 = '✓'
      let qs = querystring.stringify(filters)

      if (PROPERTIES.USE_CACHE) {
        if (state.offers.length > 0) {
          console.info('offers:', PROPERTIES.CACHE_MESSAGE)
          return
        }
      }

      http.get(`/offers?${qs}`)
        .then((resp) => {
          state.offers = resp.data
        })
        .catch((err) => {
          console.error('offers/getOffers:', err)
          throw err
        })
    },
  },

  actions: {
    getOffers({ commit }, filters) { commit('getOffers', filters) },
  },

  getters: {
    existingMaterials: state => {
      const list = []
      state.offers.forEach((el) => {
        el.materials.forEach((m) => {
          if (!list.some(ex => ex === m.main)) {
            list.push(m.main)
          }
        })
      })
      return list
    },

    offerStatusesOptions: state => {
      return state.offerStatuses
        .map(el => {
          return {
            text: el.name,
            value: el.id,
          }
        })
    },

    offerTypesOptions: state => {
      return state.offerTypes
        .map(el => {
          return {
            text: el.name,
            value: el.id
          }
        })
    },

    sortByOptions() {
      return [
        {
          text: 'Publicación más antigua',
          value: 'publish_asc',
        },
        {
          text: 'Publicación más reciente',
          value: 'publish_desc',
        },
        {
          text: 'Actualización más antigua',
          value: 'update_asc',
        },
        {
          text: 'Actualización más reciente',
          value: 'update_desc',
        },
      ]
    },
  }
}

export { OffersStore }
