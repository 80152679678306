import DiscardForm from '@/components/DiscardForm.vue'
import OfferForm from '@/apps/offer_form.vue'
import UnlockOffer from '@/apps/unlock_offer.vue'
import OffersFilters from '@/apps/offers_filters.vue'
import AddCreditsModal from '@/components/AddCreditsModal.vue'
import UserMaterialsApp from '@/apps/user_materials.vue'

const apps = {
  addCreditsModal: {
    selector: '#add-credits-modal',
    component: AddCreditsModal,
    data(container) {
      return {
      }
    },
    created(container) {
    },
  },

  discardForm: {
    selector: 'a.offer-discard',
    component: DiscardForm,
    data(container) {
      return {}
    },
    props(container) {
      const url = container.href

      return {
        url,
      }
    },
    created(container) {
    },
  },

  offersFilters: {
    selector: '#offers-filters',
    component: OffersFilters,
    data(container) {
      let qs = {}
      const url = container.dataset['url']

      if (container.dataset['qs']) {
        qs = JSON.parse(container.dataset['qs'])
      }

      return {
        qs,
        url,
      }
    },
    created(container) {
      this.$store.dispatch('getCatalogues')
      this.$store.dispatch('account/setAdmin', container.dataset['offerStatus'] == 1)
    },
  },

  offerForm: {
    selector: '#upload-form',
    component: OfferForm,
    data(container) {
      return {}
    },
    created(container) {
      const dataset = container.dataset

      this.$store.dispatch('uploadForm/resetOffer')
      this.$store.dispatch('getCatalogues')
        .then((resp) => {
          this.$store.dispatch('setRecaptchaKey', dataset['recaptchaKey'])
          this.$store.dispatch('setHere', dataset['here'])

          if (dataset['offerId']) {
            this.$store.commit('uploadForm/loadOffer', dataset['offerId'])
          } else if (dataset['contactEmail']) {
            this.$store.dispatch('uploadForm/resetOffer')
            this.$store.dispatch('uploadForm/updateContactEmail', dataset['contactEmail'])
            this.$store.dispatch('uploadForm/updateContactName', dataset['contactName'])
            this.$store.dispatch('uploadForm/updateContactPhone', dataset['contactPhone'])
          }
          if (dataset['editDate']) {
            this.$store.dispatch('account/setAdmin', dataset['editDate'])
          }

          this.$store.dispatch('uploadForm/toggleRequestInProcess', false)
        })
    },
  },

  unlockOffer: {
    selector: '#unlock-offer',
    component: UnlockOffer,
    data(container) {
      return {
        balance: Number(container.dataset['balance']),
        price: Number(container.dataset['price']),
        token: container.dataset['token'],
        url: container.dataset['url']
      }
    },
    created(container) {
    },
  },

  userMaterialsApp: {
    selector: '#user-materials',
    component: UserMaterialsApp,
    data(container) {
      return {
      }
    },
    created(container) {
      this.$store.dispatch('materials/getMaterials')
    },
  },
}

export default apps
