import { debounce } from 'lodash'
import http from '@/services/http'
import PROPERTIES from '@/config/properties'

const MaterialsStore = {
  state: {
    materials: [],
    features: [],
    inProgress: false,
  },

  mutations: {
    setFeatures(state, features) { state.features = Array.from(features) },

    updateMaterials(state, payload) {
      if (PROPERTIES.USE_CACHE) {
        if (state.materials.length > 0) {
          console.info('materials:', PROPERTIES.CACHE_MESSAGE)
          return
        }
      }

      http.get('/catalogues/materials.json')
        .then((resp) => {
          state.materials = resp.data
        })
        .catch((err) => {
          console.error('updateMaterials:', err)
          throw err
        })
    },
  },

  actions: {
    getMaterials({ commit }, payload) {
      commit('updateMaterials')
    },

    filterFeatures: debounce(({ commit }, query) => {
      query = window.encodeURIComponent(query)

      http.get('/catalogues/offer_tags', { params: { query } })
        .then(resp => commit('setFeatures', resp.data.tags))
        .catch(err => {
          console.error('Error fetching tags:', err)
        })
    }, 600),
  },

  getters: {
    featuresOptions: state => {
      return state.features.map(tag => {
        return {
          text: tag,
          value: tag,
        }
      })
    },

    /**
     * Get list of materials' main family for broader filtering
     */
    materialOptions: state => {
      const list = []

      state.materials
        .forEach((el) => {
          if (list.indexOf(el.main) === -1) {
            list.push(el.main)
          }
        })

      return list.map(el => {
        return {
          text: el,
          value: el
        }
      })
    },
  }
}

export { MaterialsStore }
