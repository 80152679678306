<style lang="scss">
  .user-materials {
    .submit {
      transition: all 0.5s ease-in !important;
      box-shadow: 0 0 0 green;

      &.hint {
        box-shadow: 0 0 1rem green !important;
      }
    }
  }
</style>

<template>
  <sui-grid
    class="user-materials"
    :doubling="true"
    :stackable="true"
    id="user-materials"
  >
    <sui-grid-row :columns="2">
      <sui-grid-column>
        <selected-materials
          :show-conditions="false"
          :selected="materials"
          @delete="removeMaterial($event)"
        />
      </sui-grid-column>
      <sui-grid-column>
        <materials-form
          @input="addMaterial($event)"
          :show-conditions="false"
        />
      </sui-grid-column>
    </sui-grid-row>

    <sui-grid-row :columns="1">
      <sui-grid-column class="text-right">
        <sui-button
          color="green"
          content="Guardar"
          type="button"
          @click="submit"
          :class="{ hint: dirty, submit: true }"
          :disabled="!sending"
        />
      </sui-grid-column>
    </sui-grid-row>
  </sui-grid>
</template>

<script>
import http from '@/services/http'
import MaterialsForm from '@/components/MaterialsForm.vue'
import SelectedMaterials from '@/components/SelectedMaterials.vue'

export default {
  name: 'UserMaterialsApp',
  components: {
    MaterialsForm,
    SelectedMaterials,
  },
  data() {
    return {
      dirty: false,
      sending: false,
      materials: [],
    }
  },
  methods: {
    addMaterial(material) {
      this.dirty = true
      this.materials.push(material)
    },
    removeMaterial(material) {
      material.locked = true

      http.delete(`/me/notifications/material/${material.material_id}`)
        .then((resp) => {
          const idx = this.materials.indexOf(material)
          if (idx !== -1) {
            this.materials.splice(idx, 1)
          }
        })
        .catch((err) => {
          console.error('Could not delete material notification', err)
          material.locked = false
        })
    },
    submit() {
      const data = {
        account: {
          notifications: {
            user_materials: this.materials,
          },
        },
      }

      this.sending = true
      http.put('/me/notifications', data)
        .then(resp => resp.data)
        .then((data) => {
          if (data.view) {
            Turbolinks.visit(data.view)
          } else {
            console.info('userMaterials: no view to redirect to')
            window.location.href = '/me/notifications'
          }
        })
        .catch((err) => {
          console.error('Could not update notifications', err)
          this.sending = false
        })
    },
    getNotifications() {
      http.get('/me/notifications.json')
        .then(resp => resp.data)
        .then((data) => {
          data.notifications.user_materials.forEach(el => el.locked = false)
          this.materials = data.notifications.user_materials
        })
        .catch((err) => { console.error('Could not fetch notifications', err) })
    },
  },
  computed: {
  },
  mounted() {
    this.getNotifications()
  },
}
</script>
