import http from '@/services/http'
import querystring from 'querystring'
import PROPERTIES from '@/config/properties'
import { genAddressCustomLabel } from '@/services/upload_form'

const SUGGESTIONS_PATH = '/addresses/autocomplete'

/**
 * Compare strings in alphabetical manner.
 *
 * @man strcmp(3p)
 * @return {number}
 */
function nameComparator(a, b) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }

  return 0
}

const AddressesStore = {
  state: {
    company: null,
    countries: [],
    errors: {},
    states: [],
    suggestions: [],
    isFetchingSuggestions: false,
  },

  mutations: {
    setCountries(state, countries) { state.countries = countries },

    getCountries(state) {
      if (PROPERTIES.USE_CACHE) {
        if (state.countries.length > 0) {
          console.info('countries:', PROPERTIES.CACHE_MESSAGE)
          return
        }
      }

      http.get('/catalogues/countries')
        .then((resp) => {
          state.countries = resp.data
        })
        .catch((err) => {
          console.error('addresses/getCountries:', err)
          throw err
        })
    },

    setStates(state, states) { state.states = states },

    getStates(state, countryId = 1) {
      http.get(`/catalogues/countries/${countryId}/states`)
        .then((resp) => {
          state.states = resp.data
        })
        .catch((err) => {
          console.error(`addresses/getStates(${countryId}):`, err)
          throw err
        })
    },

    /**
     * Get address suggestions from free-form input
     *
     * @param {string} searchText Free-form input text to look up
     * @return {null}
     */
    searchAddress(state, searchText) {
      // TODO: don't hard-code `5`, use a constant
      if (searchText && searchText.length < 5) {
        return
      }
      const params = { query: searchText }
      const url = `${SUGGESTIONS_PATH}?${querystring.stringify(params)}`

      state.isFetchingSuggestions = true
      http.get(url, { query: searchText })
        // update countries and states & add custom label
        .then((resp) => {
          const suggestions = resp.data.suggestions
          let a = null
          suggestions.forEach((el) => {
            a = el.address

            // add missing countries and states
            if (!state.countries.some(c => c.id === a.country_id)) {
              state.countries.push({
                id: a.country_id,
                name: a.country,
                short_code: el.countryCode,
              })
            }
            //if (!state.states.some(s => s.id === a.state_id)) {
            //  state.states.push({
            //    country_id: a.country_id,
            //    id: a.state_id,
            //    name: a.state,
            //  })
            //}

            el.customLabel = genAddressCustomLabel(a)
          })
          return suggestions
        })
        .then((suggestions) => {
          state.suggestions = suggestions
          state.isFetchingSuggestions = false
        })
        .catch((err) => {
          console.error('searchAddress:', err)
          state.isFetchingSuggestions = false
        })
    },

    resetSuggestions(state) { state.suggestions = [] },
    setFetchingSuggestions(state, b) { state.isFetchingSuggestions = b },
  },

  actions: {
    getCountries({ commit }) { commit('getCountries') },
    updateStates({ commit }, countryId) { commit('getStates', countryId) },
    searchAddress({ commit }, text) { commit('searchAddress', text) },
    setCountries({ commit }, countries) { commit('setCountries', countries) },
    setStates({ commit }, states) { commit('setStates', states) },
    resetSuggestions({ commit }) { commit('resetSuggestions') },
    setFetchingSuggestions({ commit }, isFetching) { commit('setFetchingSuggestions', isFetching) },
  },

  getters: {
    countriesOptions: state => {
      return state.countries
        .sort(nameComparator)
        .map((el) => {
          return {
            key: el.id,
            text: el.name,
            value: el.id
          }
        })
    },

    statesOptions: state => {
      return state.states
        .sort(nameComparator)
        .map((el) => {
          return {
            key: el.id,
            text: el.name,
            value: el.id
          }
        })
    },

    getStateById: (state) => (id) => {
      return state.states.find(s => s.id === id)
    },

    getCountryById: (state) => (id) => {
      return state.countries.find(s => s.id === id)
    },
  }
}

export { AddressesStore }
