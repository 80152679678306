<template>
  <div class="offer-bubble">
    <header>
      <span class="offer-price ui label">
        Precio x Kg.
        <span class="detail">
          {{ offerPrice }}
        </span>
      </span>
      <span class="offer-qty ui label">{{ offerTotalQty }}</span>
    </header>

    <ul>
      <li v-for="offerMaterial in offer.materials">
        {{ offerMaterialLabel(offerMaterial) }}
      </li>
    </ul>

    <footer class="text-right">
      <a :href="offerUrl" target="_blank">
        Ver {{ offer.human_id }}
      </a>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { currency, weight } from '@/filters'

export default {
  props: {
    offer: { type: Object, required: true },
  },

  computed: {
    totalQty() {
      let total = 0
      if (this.offer) this.offer.materials.forEach((el) => total += el.qty)
      return total
    },

    offerTotalQty() {
      return weight(this.totalQty)
    },

    offerPrice() {
      return currency(this.offer.price)
    },

    offerUrl() {
      if (!this.offer?.id)
        return '#'

      return `/offers/${this.offer.id}`
    },
  },

  methods: {
    offerMaterialLabel(offerMaterial) {
      return `${offerMaterial.main}, ${offerMaterial.secondary} - ${weight(offerMaterial.qty)}`
    },
  }
}
</script>

<style lang="scss">
.offer-bubble {
  font-size: small;

  ul {
    padding: 0;
    margin: 0.5em 0 0.5em 0.5em;
  }

  li {
    line-height: 1.25em;
  }

  .ui.label {
    font-size: smaller;
  }
}
</style>
