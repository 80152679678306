<style lang="scss" scoped>
.contact-information {
  @media (min-width: 575px) {
    div {
      &#contactName, &#company, &#email, &#phone {
        width: 200px !important;
      }
    }
  }
}
</style>

<template>
  <sui-form
    @submit.prevent.stop=""
    name="contact"
    method="POST"
    class="contact-information"
  >
    <sui-form-field>
      <label for="contactName">Nombre *</label>
      <sui-input
        placeholder="Pepe Pecas"
        id="contactName"
        name="contactName"
        required
        v-model="contactName"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="company">Compañía</label>
      <sui-input
        placeholder="Mi Compañía"
        id="company"
        name="company"
        v-model="company"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="email">Email *</label>
      <sui-input
        placeholder="pepe.pecas@gmail.com"
        id="email"
        name="email"
        required
        v-model="email"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="phone">Teléfono *</label>
      <sui-input
        placeholder="81 1234 5678"
        id="phone"
        name="phone"
        v-model="phone"
      />
    </sui-form-field>

    <sui-form-field>
      <label for="description">Información adicional</label>
      <textarea
        placeholder="Información extra que pueda ser útil"
        rows="4"
        id="description"
        name="description"
        v-model="description"
      ></textarea>
    </sui-form-field>
  </sui-form>
</template>

<script>
export default {
  computed: {
    company: {
      get() { return this.$store.state.uploadForm.form.contact.company },
      set(v) { return this.$store.commit('uploadForm/updateContactCompany', v) }
    },
    email: {
      get() { return this.$store.state.uploadForm.form.contact.email },
      set(v) { return this.$store.commit('uploadForm/updateContactEmail', v) }
    },
    contactName: {
      get() { return this.$store.state.uploadForm.form.contact.name },
      set(v) { return this.$store.commit('uploadForm/updateContactName', v) }
    },
    phone: {
      get() { return this.$store.state.uploadForm.form.contact.phone },
      set(v) { return this.$store.commit('uploadForm/updateContactPhone', v) }
    },
    description: {
      get() { return this.$store.state.uploadForm.form.contact.description },
      set(v) { return this.$store.commit('uploadForm/updateContactDescription', v) }
    }
  }
}
</script>
