<template>
  <aside class="unlock-offer">
    <sui-button
      type="button"
      size="mini"
      color="green"
      @click.native="toggle"
    >
      <sui-icon name="unlock alternate" />
      Ver datos de contacto
    </sui-button>
    <span is="sui-label" tag>
      {{ price | currency }} créditos
    </span>
    <sui-modal size="small" v-model="open">
      <header class="header">Desbloquear</header>
      <article
        class="content"
        itemprop="seller"
        itemscope
        itemtype="https://schema.org/Organization"
      >
				<p v-if="balance >= price">
					Cuentas con <strong>{{ balance | currency }}</strong> créditos,
					esto reducirá <strong>{{ price | currency }}</strong>.
					¿Estás seguro?
				</p>
				<p v-else>
          No cuentas con los fondos suficientes
          <strong v-if="balance == -1">
            &mdash; registrate y obten $50 de cortesía!
          </strong>
        </p>
      </article>
      <sui-modal-actions class="mb-1">
				<sui-form :action="url" accept-charset="UTF-8" method="POST">
					<input type="hidden" name="authenticity_token" :value="token" />
					<sui-button
            size="small"
						type="button"
						negative
						@click.native="toggle"
					>
						Cancelar
					</sui-button>
					<sui-button
						type="submit"
						positive
						:disabled="balance < price"
					>
						Confirmar
					</sui-button>
				</sui-form>
      </sui-modal-actions>
    </sui-modal>
  </aside>
</template>

<script>
export default {
	props: {
		balance: { type: Number, required: true },
		price: { type: Number, required: true },
		token: { type: String, required: true },
		url: { type: String, required: true }
	},
  data() {
    return {
      open: false
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    }
  }
}
</script>
