<template>
  <sui-form
    @submit.stop.prevent=""
    name="address"
    method="POST"
    class="address-form"
  >
    <here-map
      :api-key="here"
      v-if="here"
      @suggestionSelected="suggestionSelected($event)"
    />

    <hr>

    <div class="two fields">
      <div :class="fieldClasses">
        <label for="country">País *</label>
        <sui-dropdown
          placeholder="País"
          class="country-address-select"
          search
          selection
          :labeled="true"
          :options="countriesOptions"
          v-model="country"
          :disabled="!override"
        />
      </div>

      <div :class="fieldClasses">
        <label for="state">Estado *</label>
        <sui-dropdown
          placeholder="Estado"
          class="state-address-select"
          search
          selection
          :labeled="true"
          :options="statesOptions"
          v-model="state"
          :disabled="!override"
        />
      </div>
    </div>

    <div class="three fields">
      <div :class="fieldClasses">
        <label for="city">Ciudad *</label>
        <sui-input
          id="city"
          placeholder="Monterrey"
          v-model="city"
          :disabled="!override"
        />
      </div>

      <div :class="fieldClasses">
        <label for="street">Calle</label>
        <sui-input
          id="street"
          placeholder="Calle Número, Colonia"
          v-model="street"
          :disabled="!override"
        />
      </div>

      <div :class="fieldClasses">
        <label for="zip_code">C.P.</label>
        <sui-input
          id="zip_code"
          placeholder="55000"
          v-model="zipCode"
          :disabled="!override"
        />
      </div>
    </div>

    <div class="field">
      <sui-button
        type="button"
        size="tiny"
        class="w-full"
        color="blue"
        :content="overrideLabel"
        @click="override = true"
      />
    </div>
  </sui-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import HereMap from '@/components/here/Map'
import { unaccent } from '@/services/strings'

export default {
  components: {
    HereMap,
  },

  data() {
    return {
      addressText: null,
      override: false,
      timeoutId: null,
    }
  },

  methods: {
    ...mapActions('uploadForm', [ 'selectSuggestion', ]),

    /**
     * Fetch address suggestions
     *
     * This uses a debounce function to search 300 milliseconds after user has stopped typing.
     */
    addressTypeahead(val) {
      if (this.timeoutId)
        window.clearTimeout(this.timeoutId)

      this.timeoutId = window.setTimeout(() => {
        this.searchAddress(val)
      }, 300)
    },

    /**
     * Handle user address suggestion selection
     */
    suggestionSelected(result) {
      const address = result.address
      let c = unaccent(address.countryName),
        country = this.countries.find(el => el.name == c),
        s = unaccent(address.state),
        state = this.states.find(el => el.name == s),
        addressLine = ''

      if (!country)
        country = this.countries.find(el => el.name.startsWith(c.split(' ')[0]))
      if (!state)
        state = this.states.find(el => el.name.startsWith(s.split(' ')[0]))

      if (address.street) addressLine += `${address.street} `
      if (address.houseNumber) addressLine += address.houseNumber
      if (address.district) addressLine += `, ${address.district}`

      if (addressLine.length > 0)
        this.street = addressLine
      this.city = address.city
      this.zipCode = address.postalCode
      this.lat = result.position.lat
      this.lon = result.position.lng

      if (c && country) {
        this.country = country.id
      }
      if (s && state) {
        this.state = state.id
      }
    },
  },

  computed: {
    ...mapState('addresses', [
      'countries',
      'states',
    ]),
    ...mapGetters('addresses', [
      'countriesOptions',
      'statesOptions',
      'getStateById',
      'getCountryById',
    ]),
    ...mapState({
      here: state => state.here,
    }),

    fieldClasses() {
      return {
        field: true,
        disabled: !this.override,
      }
    },

    isModified() {
      return !!this.street || !!this.zipCode || !!this.city
    },

    overrideLabel() {
      return this.isModified
        ? 'Sobrescribir'
        : 'Modificar'
    },

    lat: {
      get() { return this.$store.state.uploadForm.form.address.lat },
      set(v) { this.$store.commit('uploadForm/updateAddressLat', v) },
    },
    lon: {
      get() { return this.$store.state.uploadForm.form.address.lon },
      set(v) { this.$store.commit('uploadForm/updateAddressLon', v) },
    },
    street: {
      get() { return this.$store.state.uploadForm.form.address.street1 },
      set(v) { this.$store.commit('uploadForm/updateAddressStreet', v) },
    },
    zipCode: {
      get() { return this.$store.state.uploadForm.form.address.zip_code },
      set(v) { this.$store.commit('uploadForm/updateAddressZipCode', v) },
    },
    city: {
      get() { return this.$store.state.uploadForm.form.address.city },
      set(v) { this.$store.commit('uploadForm/updateAddressCity', v) },
    },
    state: {
      get() { return this.$store.state.uploadForm.form.address.state_id },
      set(v) { this.$store.commit('uploadForm/updateAddressState', v) },
    },
    country: {
      get() { return this.$store.state.uploadForm.form.address.country_id },
      set(v) { this.$store.dispatch('uploadForm/updateAddressCountry', v) },
    },
  },
}
</script>

<style lang="scss">
.address-form {
  kbd {
    color: #fff;
    background-color: #212529;
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
  }
}
</style>
