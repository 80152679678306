import http from '@/services/http'
import { json2FormData } from '@/services/upload_form'

const requiredFields = [
]

const DEFAULT_ERRORS = [
  'Ocurrió un error al procesar la petición. Si el problema persiste, por favor reportalo al equipo de Supraciclaje.',
]
const VALID_ERRORS_HEADERS = [
  'application/json',
  'application/json; charset=utf-8',
]

function newUploadForm() {
  return {
    address: {
      street1: null,
      zip_code: null,
      city: null,
      state_id: 1,
      country_id: 1,
      lat: null,
      lon: null,
    },
    comments: null,
    contact: {
      company: null,
      email: null,
      name: null,
      phone: null,
      description: null,
    },
    id: null,
    images: null,
    existingImages: [],
    invoice: false,
    materials: [],
    name: null,
    offer_type: "sell",
    publish_date: null,
    offer_status: null,
    is_verified: true,
    price: 1,
    recaptchaResponse: null,
  }
}

const UploadFormStore = {
  state: {
    form: {
      address: {
        street1: null,
        zip_code: null,
        city: null,
        state_id: 1,
        country_id: 1,
        lat: null,
        lon: null,
      },
      comments: null,
      contact: {
        company: null,
        email: null,
        name: null,
        phone: null,
        description: null,
      },
      id: null,
      images: null,
      existingImages: [],
      invoice: false,
      materials: [],
      name: null,
      offer_type: "sell",
      publish_date: null,
      offer_status: null,
      is_verified: true,
      price: 1,
      recaptchaResponse: null,
    },
    requestInProcess: false,
    offerErrors: [],
  },

  mutations: {
    resetForm(state) { state.form = newUploadForm() },

    submitOffer(state, data) {
      const offerFormData = json2FormData(data)
      state.offerErrors = []
      let prom = null

      if (state.form.id) {
        prom = http.put(`/offers/${state.form.id}`, offerFormData)
      } else {
        prom = http.post('/offers', offerFormData)
      }

      prom
        .then((resp) => {
          const data = resp.data

          return data
        })
        .then((data) => {
          // reset form state
          state.form = newUploadForm()

          if (data.view) {
            Turbolinks.visit(data.view)
          } else {
            console.info('uploadForm/submitOffer: no view to redirect to')
            window.alert('Oferta guardada')
            window.location.href = '/'
          }
        })
        .catch((err) => {
          console.error('uploadForm/submitOffer:', err)

          state.requestInProcess = false
          if (err.response) {
            let contentType = err.response.headers['content-type']
            const idx = VALID_ERRORS_HEADERS.indexOf(contentType.toLowerCase())

            if (idx >= 0) {
              state.offerErrors = err.response.data
            } else {
              console.debug('Invalid Content-Type for errors:', err.response.headers['content-type'])
              state.offerErrors = DEFAULT_ERRORS
            }
          }

          throw err
        })
    },
    loadOffer(state, offerId) {
      return http.get(`/offers/${offerId}.json`)
        .then((resp) => {
          state.form.comments = resp.data.description
          state.form.id = offerId
          state.form.invoice = resp.data.billable
          state.form.name = resp.data.name
          state.form.offer_type = resp.data.offer_type
          state.form.publish_date = resp.data.created_at
          state.form.offer_status = resp.data.offer_status
          state.form.price = resp.data.price
          resp.data.images.forEach(img => state.form.existingImages.push(img))

          if (resp.data.materials) state.form.materials = resp.data.materials
          if (resp.data.address) state.form.address = resp.data.address
          if (resp.data.contact) state.form.contact = resp.data.contact

          return resp.data
        })
    },
    deleteImage(state, id) {
      console.debug('deleteImage: id =>', id)
      return http.delete(`/offers/${state.form.id}/assets/${id}`)
        .then(resp => {
          let index = state.form.existingImages.findIndex(el => el.id === id)

          console.debug('deleteImage: index =>', index)
          if (index > -1) {
            state.form.existingImages.splice(index, 1)
          }
        })
        .catch(err => {
          console.error('Error deleting image.', err)
        })
    },
    setOfferErrors(state, errors) { state.offerErrors = errors },
    updateAddressStreet(state, street) { state.form.address.street1 = street },
    updateAddressZipCode(state, zipCode) { state.form.address.zip_code = zipCode },
    updateAddressCity(state, city) { state.form.address.city = city },
    updateAddressState(state, stateId) { state.form.address.state_id = stateId },
    updateAddressCountry(state, countryId) { state.form.address.country_id = countryId },
    updateAddressLat(state, lat) { state.form.address.lat = lat },
    updateAddressLon(state, lon) { state.form.address.lon = lon },
    updateContactCompany(state, company) { state.form.contact.company = company },
    updateContactEmail(state, email) { state.form.contact.email = email },
    updateContactName(state, name) { state.form.contact.name = name },
    updateContactPhone(state, phone) { state.form.contact.phone = phone },
    updateContactDescription(state, description) { state.form.contact.description = description },
    updateImages(state, images) { state.form.images = images },
    updateComments(state, comments) { state.form.comments = comments },
    updateInvoice(state, invoice) { state.form.invoice = invoice },
    updateName(state, name) { state.form.name = name },
    updateOfferStatus(state, offerStatusId) { state.form.offer_status = offerStatusId },
    updateOfferType(state, offerType) { state.form.offer_type = offerType },
    updatePublishDate(state, date) { state.form.publish_date = date },
    updateVerification(state, flag) { state.form.is_verified = !!flag },
    updatePrice(state, price) { state.form.price = price },

    addSelectedMaterial(state, material) {
      const _m = state.form.materials.find(el => el.material_id === material.material_id)

      // update material if already in collection
      if (_m) {
        const idx = state.form.materials.indexOf(_m)
        _m.qty = material.qty
        _m.conditions = material.conditions
        _m._destroy = 0
        state.form.materials.splice(idx, 1, _m)
      } else {
        state.form.materials.push(material)
      }
    },

    /**
     * Toggle `_destroy` property in material.
     *
     * Changing properties in array items does *NOT* kick vue's reactivity,
     * so we copy the object, toggle the flag & replace the old item.
     *
     * If we set the `_destroy` property when we're creating
     * (as opposed to editing) an offer, the payload will include an entry in
     * the `offer_materials` array but it won't create a record AND it will
     * not trigger a validation error, so if we're not editing, we remove
     * the entry.
     */
    removeSelectedMaterial(state, idx) {
      const el = state.form.materials[idx]
      if (el.id) {
        el._destroy = 1
        state.form.materials.splice(idx, 1, el)
      } else {
        state.form.materials.splice(idx, 1)
      }
    },

    toggleRequestFlag(state, flag) { state.requestInProcess = !!flag },
    setRecaptchaResponse(state, response) { state.form.recaptchaResponse = response },

  },

  actions: {
    submit({ commit, state, dispatch }, payload) {
      commit('toggleRequestFlag', true)

      return new Promise((resolve, reject) => {
        let k = null, shouldFail = false
        const errors = {}

        for (const i in requiredFields) {
          k = requiredFields[i]
          if (!state[k]) {
            if (!errors[k]) { errors[k] = [] }
            errors[k].push('es inválido')
            shouldFail = true
          }
        }

        if (state.form.materials.length < 1) {
          if (!errors.materials) { errors.materials = [] }
          errors.materials.push('no puede estar vacío')
          shouldFail = true
        }

        if (shouldFail) {
          reject(errors)
          return
        }

        resolve(true)
      }).then(() => {
        const data = {
          address: state.form.address,
          billable: state.form.invoice,
          contact: state.form.contact,
          description: state.form.comments,
          images: state.form.images,
          name: state.form.name,
          offer_type: state.form.offer_type,
          offer_materials: state.form.materials,
          publish_date: state.form.publish_date,
          offer_status: state.form.offer_status,
          is_verified: state.form.is_verified,
          price: state.form.price,
          recaptcha_response: state.form.recaptchaResponse,
        }

        commit('submitOffer', data)
      }).catch((err) => {
        dispatch('showOfferErrors', err)
        commit('toggleRequestFlag')
      })
    },
    resetOffer({ commit }) { commit('resetForm') },
    showOfferErrors({ commit }, errors) { commit('setOfferErrors', errors) },
    updateAddressStreet({ commit }, street) { commit('updateAddressStreet', street) },
    updateAddressZipCode({ commit }, zipCode) { commit('updateAddressZipCode', zipCode) },
    updateAddressCity({ commit }, city) { commit('updateAddressCity', city) },
    updateAddressState({ commit }, state) { commit('updateAddressState', state) },
    updateAddressCountry({ commit, dispatch }, country) {
      dispatch('addresses/updateStates', country, { root: true })
      commit('updateAddressCountry', country)
    },
    updateAddressLat({ commit }, lat) { commit('updateAddressLat', lat) },
    updateAddressLon({ commit }, lon) { commit('updateAddressLon', lon) },
    updateContactCompany({ commit }, company) {
      commit('updateContactCompany', company)
    },
    updateContactEmail({ commit }, email) {
      commit('updateContactEmail', email)
    },
    updateContactName({ commit }, name) {
      commit('updateContactName', name)
    },
    updateContactPhone({ commit }, phone) {
      commit('updateContactPhone', phone)
    },
    updateContactDescription({ commit }, description) {
      commit('updateContactDescription', description)
    },
    updateImages({ commit }, images) { commit('updateImages', images) },
    updateAddressId({ commit }, address) { commit('updateAddressId', address) },
    updateComments({ commit }, comments) { commit('updateComments', comments) },
    updateCompany({ commit }, companyId) { commit('updateCompany', companyId) },
    updateInvoice({ commit }, invoice) { commit('updateInvoice', invoice) },
    updateName({ commit }, name) { commit('updateName', name) },
    updatePublishDate({ commit }, date) { commit('updatePublishDate', date) },
    updateVerification({ commit }, flag) { commit('updateVerification', flag) },
    updatePrice({ commit }, price) { commit('updatePrice', price) },
    setRecaptchaResponse({ commit }, response) {
      commit('setRecaptchaResponse', response)
    },
    toggleRequestInProcess({ commit }, flag) {
      commit('toggleRequestFlag', flag)
    },
    addSelectedMaterial({ commit, state }, material) {
      commit('addSelectedMaterial', material)
    },
    removeSelectedMaterial({ commit, state }, material) {
      const idx = state.form.materials.indexOf(material)
      if (idx !== -1) {
        commit('removeSelectedMaterial', idx)
      }
    },

    selectSuggestion({ commit }, suggestion) {
      commit('selectSuggestion', suggestion)
    },
  },

  getters: {
    isCreateButtonDisabled: (state) => {
      return state.requestInProcess
    },
    totalQty: (state) => {
      let total = 0
      state.form.materials.forEach((el) => total += el.qty)
      return total
    },
  },
}

export { UploadFormStore }
