<template>
  <sui-form @submit.stop.prevent="addMaterial" class="materials-form">
    <sui-form-field>
      <sui-dropdown
        placeholder="Material *"
        selection
        :labeled="true"
        :options="materialOptions"
        v-model="material.main"
      />
    </sui-form-field>

    <sui-form-field>
      <sui-dropdown
        placeholder="Submaterial *"
        selection
        :options="submaterials"
        v-model="material.secondary"
      />
    </sui-form-field>

    <sui-form-field v-if="showConditions">
      <sui-dropdown
        placeholder="Características/Tags"
        multiple
        fluid
        search
        selection
        allow-additions
        v-model="material.featureList"
        :labeled="true"
        :options="featuresOptions"
        @filtered="filterFeatures"
      />
    </sui-form-field>

    <sui-form-field v-if="showConditions">
      <sui-input
        placeholder="Condición, e.g., tiene chasís oxidado, etc. *"
        v-model="material.conditions"
      />
    </sui-form-field>

    <sui-form-fields :fields="2">
      <sui-form-field>
        <sui-input
          placeholder="Cantidad *"
          type="number"
          step="any"
          min="1"
          id="qty"
          name="qty"
          required
          v-model="material.qty"
        />
      </sui-form-field>

      <sui-form-field>
        <sui-dropdown
          labeled
          :options="weightUnits"
          selection
          text="Unidades"
          v-model="weightUnit"
        />
      </sui-form-field>
    </sui-form-fields>

    <sui-button
      type="reset"
      content="Descartar"
      @click="discard"
    />
    <sui-button
      color="green"
      type="button"
      content="Agregar material"
      @click="addMaterial"
      :disabled="isDisabled"
    />
  </sui-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    showConditions: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      material: {
        featureList: [],
        main: null,
        secondary: null,
        conditions: null,
        qty: 1,
      },
      weightUnit: 1,
    }
  },

  methods: {
    ...mapActions({
      filterFeatures: 'materials/filterFeatures',
    }),

    addMaterial() {
      const m = this.searchMaterial(this.material.main, this.material.secondary)

      if (m) {
        const data = {
          feature_list: this.material.featureList,
          conditions: this.material.conditions,
          material_id: m.id,
          main: m.main,
          secondary: m.secondary,
          qty: this.material.qty * this.weightUnit,
        }

        this.$emit('input', data)
        this.discard()
      }
    },

    /**
     * Get material by its main & secondary properties.
     *
     * @param  String main material
     * @param  String secondary material
     * @return Object || null
     */
    searchMaterial(main, secondary) {
      return this.materials
        .find(el => el.main === main && el.secondary === secondary)
    },

    discard() {
      this.material = {
        main: null,
        secondary: null,
        conditions: null,
        qty: 1,
      }
      this.weightUnit = 1
    },
  },

  computed: {
    ...mapState({
      materials: state => state.materials.materials,
      weightUnits: state => state.weightUnits,
    }),

    ...mapGetters({
      featuresOptions: 'materials/featuresOptions',
      materialOptions: 'materials/materialOptions',
    }),

    submaterials() {
      const list = []

      for (const i in this.materials) {
        if (this.materials[i].main === this.material.main) {
          list.push({
            text: this.materials[i].secondary,
            value: this.materials[i].secondary
          })
        }
      }

      return list
    },

    isDisabled() {
      return !this.material.main || !this.material.secondary
    }
  },

  mounted() {
    // get initial list of features/tags
    this.filterFeatures('')
  },
}
</script>

<style lang="scss">
.materials-form {
  > .field:not(:first-child) {
    margin: 0.5rem 0 !important;
  }
}
</style>
